import React from "react"
import styled from "styled-components"

const Constraints = styled.div`
  margin: 0 auto;
  padding: 100px 20px 0;
  max-width: ${props => props.maxWidth || props.theme.maxWidth};
  @media (max-width: 1040px) {
    width: 100%;
  }
`
export default Constraints
