import React from "react"
import SEO from "../components/seo"
import styled from "styled-components"
import Header from "../components/header"
import GlobalStyle from "../components/GlobalStyle"
import Theme from "../components/Theme"
import Constraints from "../components/Constraints"
import Layout from "../components/layout"
import { Link } from "gatsby"

const ReleasesPage = props => (
  <Theme>
    <GlobalStyle />
    <SEO
      title="Shotty Releases"
      keywords={[`shotty`, `screenshots`, `mac`, `releases`]}
      image="/images/twitter-card-releases.png"
      description="Version history for Shotty."
    />
    <Header />
    <Layout>
      <Constraints maxWidth={"1040px"}>
        <Marquee>
          <h3>Releases</h3>
        </Marquee>
        <Content>
          <Section>
            <div className="release">v1.8</div>
            <div className="details">
              <h4>What's new</h4>
              <ul>
                <li>Dark mode is here! 🌒</li>
              </ul>
            </div>
          </Section>
          <Section>
            <div className="release">v1.7</div>
            <div className="details">
              <h4>What's new</h4>
              <ul>
                <li>Double clicking a screenshot now opens it in Preview</li>
                <li>
                  Fixed some issues in Mojave where Shotty wasn't detecting
                  screenshots saved to custom locations.
                </li>
                <li>Bug fixes</li>
              </ul>
            </div>
          </Section>
          <Section>
            <div className="release">v1.6</div>
            <div className="details">
              <h4>What's new</h4>
              <ul>
                <li>Bug fixes</li>
              </ul>
            </div>
          </Section>
          <Section>
            <div className="release">v1.5</div>
            <div className="details">
              <h4>What's new</h4>
              <p>
                We listened to your suggestions and added the #1 most requested
                feature:
              </p>
              <ul>
                <li>
                  Keep your desktop clean: Easily change where screenshots are
                  saved on your Mac
                  <ul>
                    <li>
                      Update:{" "}
                      <Link to="/help/change-screenshot-folder/">
                        Follow these instructions
                      </Link>{" "}
                      to choose a custom screenshot folder.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Section>
          <Section>
            <div className="release">v1.4</div>
            <div className="details">
              <h4>What's new</h4>
              <ul>
                <li>
                  One for the developers: Screenshots from Simulator now appear
                  in Shotty along with the rest of your screenshots.
                </li>
              </ul>
            </div>
          </Section>
          <Section>
            <div className="release">v1.3</div>
            <div className="details">
              <h4>What's new</h4>
              <p>
                Shotty's biggest upgrade yet. Annotate screenshots, Quick Look,
                Delete, and more. We've built the most requested features from
                our customers.
              </p>
              <ul>
                <li>
                  Annotations: Quickly mark up screenshots with text and
                  drawing.
                </li>
                <li>
                  Delete all screenshots: Keep your desktop clean with a single
                  click.
                </li>
                <li>
                  Quick Look: Select a screenshot and press the spacebar to see
                  a full-size preview.
                </li>
                <li>
                  Show in Finder: Quickly locate your screenshots in the Mac's
                  file system.
                </li>
                <li>
                  Delete individual screenshots: For those embarassing
                  screenshots.
                </li>
              </ul>
              <p>
                Also fixed a bug where the order of screenshots would sometimes
                be displayed as oldest first.
              </p>
            </div>
          </Section>
          <Section>
            <div className="release">v1.2</div>
            <div className="details">
              <h4>What's new</h4>
              <ul>
                <li>
                  Fixed an issue where screenshots sometimes couldn't be dragged
                  on of Shotty. Thanks to those of you who reported the issue.
                  We're a small team working hard to make Shotty as useful and
                  reliable as possible, and the response has been incredible.
                </li>
              </ul>
            </div>
          </Section>
        </Content>
      </Constraints>
    </Layout>
  </Theme>
)

export default ReleasesPage

const Content = styled.div`
  margin: 0 0 40px 0;
`
const Section = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  text-align: left;
  border-bottom: ${props => props.theme.line};
  padding: 40px 0;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  p {
    font-size: 18px;
  }
  font-size: 18px;
  a {
    text-decoration: underline;
  }
  h4 {
    margin: 0;
  }
  ul {
    margin: 0;
    padding: 10px 0 0 0;
    li {
      list-style-type: none;
      padding-left: 20px;
      margin: 10px 0 0 0;
    }
    li:before {
      content: "";
      float: left;
      display: list-item;
      list-style-type: disc;
      list-style-position: inside;
      width: 20px;
      margin-left: -20px;
    }
  }
`

const Marquee = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-bottom: ${props => props.theme.line};
  padding: 30px 0;
  h3 {
    font-weight: 800;
    letter-spacing: -0.8px;
    text-align: left;
  }

  @media (max-width: 600px) {
    padding: 20px 0 10px;
  }
`
